/** @jsx jsx */
import { jsx, Styled, Flex } from "theme-ui"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Button } from "rebass"
import { Link } from "gatsby"

const NotFoundPage = () => (
  <Layout locale="en">
    <SEO title="Page not found" />
    <Flex
      sx={{
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        bg: "blueLight",
      }}
    >
      <Styled.h1>Something went wrong.</Styled.h1>
      <Styled.p>Page not found.</Styled.p>
      <Button variant="primary" as={Link} to="/en/contact">
        Contact us
      </Button>
    </Flex>
  </Layout>
)

export default NotFoundPage
